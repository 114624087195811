import React from "react"
import ProductBanner from '../assets/product-banner.jpg'
import '../../src/styles/products.css'

const CommonBanner = () => {
    return (
        <>
            <div className="">
                <div className="bg-Image">
                    <div className="container-fluid Block-Banner">
                        {/* <div className="container box-wr">
                            <h1 className="Title_banner">Simple Projects </h1>
                            <p className="Subtitle_banner">Lorem Ipsum is simply dummy text of the printing and <br /> typesetting industry. Lorem Ipsum has been the  industry's <br /> standard dummy  text ever since the 1500s, when an unknown</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CommonBanner;