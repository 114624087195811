import React, { useEffect, useState } from "react";
import CommonBanner from "../components/CommonBanner";
import axios from "axios";
import { Helmet } from "react-helmet";

import '../../src/styles/ourServices.css'
import Footer from '../../src/components/Footer'
import { ourservicesURL } from "../drupal-global-Api/global";
import { renderHTMLEn } from "../components/renderHTML";

const OurServices = () => {
    const [services, ourServices] = useState([])

    useEffect(() => {
        axios.get(ourservicesURL).then(response => {
            ourServices(response.data?.content?.olivero_views_block__our_services_block_1?.data?.rows);
            console.log("Services", services)
        })
            .catch(error => {
                console.error(error);
            });
    }, [])

    return (
        <>
            <Helmet>
                <title>Dar Al Hikmah Marbles Factory - Our Services</title>
                <meta
                    name="description"
                    content="Explore the diverse range of services offered by Dar Al Hikmah Marbles Factory. From marble production to installation services, discover our expertise and quality craftsmanship."
                />
                <meta
                    name="keywords"
                    content="marble factory, marble production, marble installation, marble services, marble craftsmanship, marble supplier, marble types, natural marble, marble countertops, marble tiles, custom marble design, marble renovation"
                />
            </Helmet>

            <CommonBanner />
            <div className="container-fluid">
                <div className="container mt-2">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                        {services.map((service, index) => (
                            <div key={index} className="col mb-2">
                                <div className="card shadow-sm">
                                    {/* <img
                                        src={service?.field_image?.[0]?.url}
                                        alt=""
                                        className="card-img-top img-fluid hoverimg"
                                    /> */}
                                    <div className="col">
                                        <div className="card-body" style={{ minHeight: '210px' }}>
                                            <h2 className="card-title">{service?.title?.[0]?.value}</h2>
                                            <p className="card-text">{renderHTMLEn(service?.body?.[0]?.value)}</p>
                                            {/* Add more details or buttons as needed */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}


export default OurServices;