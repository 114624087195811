import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/header.css";
import LangLink from "./LangLink";
import i18n from "../i18n";
import { getMaster } from "../server/masterData";
import { Url } from "../drupal-global-Api/global";
import DAH from "../assets/DAH.jpeg";
import { Helmet } from "react-helmet";

const Header = (header) => {
 const [menu, setMenu] = useState([]);
  const [collapse, setCollapse] = useState(false);

 
  const [toggleSubtree, setToggleSubtree] = useState();
  let curindex = 0;
  const toggleButton = (index) => {
    // 👇️ toggle shown state
    setToggleSubtree(!index);
    if (curindex === index) {
      setToggleSubtree();
      curindex = 0;
    } else {
      setToggleSubtree(index);
      curindex = index;
    }

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  const rtlLanguages = ["ar"];
  const Onlantoogle = (language) => {
    const dir = rtlLanguages.includes(language) ? "rtl" : "ltr";
    document.documentElement.dir = dir;
    //     useEffect(() => {
    //         setToggle(true)
    //     });

    //     alert(toggle)
    //    if (toggle==false) {
    //     setToggle(false)
    //     }
  };

  useEffect(() => {
    axios
      .get(Url)
      .then((response) => {
        setMenu(response.data?.primary_menu?.olivero_main_menu?.data?.links);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
    // setMenu(Url)
  }, []);

  let pathArr = [];
  if (typeof window !== "undefined") {
    pathArr = window.location.pathname.split("/");
  } else {
    pathArr[1] = "/";
    pathArr[2] = "/";
  }
  const path = pathArr[1] === "en" ? "en" : "ar";
  // let curPage = pathArr[2];
  // if (curPage == undefined) {
  //   curPage = "home";
  // }

  return (
    <header>
       <Helmet>
        <title>Dar Al Hikmah Marbles Factory - Home</title>
        <meta name="description" content="Explore Dar Al Hikmah Marbles Factory for high-quality marble products. Discover our projects, products, and services. Contact us today!" />
      </Helmet>
     <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top header_shadow">
     <div className="container">
          {/* <a className="navbar-brand AppName" href="">DAH</a> */}
          <img src={DAH} alt="Dar Al Hikmah Logo" className="dah" />
          <button
            className="navbar-toggler"
            onClick={() => setCollapse(!collapse)}

            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${collapse ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {Object.keys(menu).map((item, i) => {
                return (
                  <li key={i.toString()} className="nav-item">
                    <LangLink
                      onClick={(e) => {
                        toggleButton(i);
                        setCollapse(false); // Add this line to toggle the collapse class
                      }}
                      to={menu[item]?.link?.url}
                    >
                      <a className={"nav-link active SubHeading"}>
                        {menu[item]?.link?.title}
                      </a>
                    </LangLink>
{/* 
                    <ul className="dropdown">
                      {Object.keys(menu[item]?.subtree).map((k, pos) => {
                        console.log(menu[item]?.subtree[k]?.link?.url);
                        return (
                          <LangLink to={path + "/" + menu[item]?.link?.url}>
                            <a key={pos} className="nav-link active SubHeading">
                              {menu[item]?.subtree[k]?.link?.title}
                            </a>
                          </LangLink>
                        );
                      })} */}
                    {/* </ul> */}
                  </li>
                );
              })}
              {path === "ar" && (
                <li className="nav-item" onClick={Onlantoogle("ar")}>
                  {/* ({
                                            path+"/home"=="en/home" ?
                                        }) */}
                  <a
                    href={"/en/"}
                    className="nav-link active SubHeading"
                  >
                    English
                  </a>
                </li>
              )}
              {path === "en" && (
                <li className="nav-item" onClick={Onlantoogle("en")}>
                  <a
                    href={"/ar/" }
                    className="nav-link active SubHeading"
                  >
                    العربية
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
