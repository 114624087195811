import React, { useState, useEffect } from "react"
import axios from "axios";
import { Helmet } from "react-helmet";
import CommonBanner from "../components/CommonBanner";
import Dropdown from 'react-bootstrap/Dropdown';
import '../../src/styles/products.css'
import Prodjson from '../../src/locales/products.json'
import Footer from "../components/Footer";
import { useTranslation, withTranslation } from 'react-i18next';
import { renderHTMLEn } from '../components/renderHTML'

import { productsURL } from "../drupal-global-Api/global";

const Products = () => {
    const [product, setProduct] = React.useState([]);
    const { t, i18n } = useTranslation();

    const [filteredList, setFilteredList] = useState(Prodjson);
    const [typeFilter, setTypeFilter] = useState('');
    const [prodType, setProdTypeFilter] = useState('');
    const [keyType, setKeyTypeFilter] = useState('');
    const [filteredList1, setFilteredList1] = useState(Prodjson);


    const filterByPropertyValue = (cityName) => {
        let itemsToShow = product.filter((item) => {
            if (typeFilter) {
                return item.field_color[0]?.value === typeFilter;
            }
            if (prodType) {
                return item.productType === prodType;
            }
            return true;
        })
        setProduct(itemsToShow)

    }


    useEffect(() => {
        axios.get(productsURL).then(response => {
            setProduct(response.data?.content?.olivero_views_block__products_block_1?.data?.rows);
            console.log("productsURL", product)
        })
            .catch(error => {
                console.error(error);
            });

    }, [productsURL])

    return (
        <>
            <Helmet>
                <title>Dar Al Hikmah Marbles Factory - Marble Products, Services, Gallery</title>
                <meta
                    name="description"
                    content="Explore Dar Al Hikmah Marbles Factory. We offer a wide range of marble products including slabs, tiles, countertops, and custom designs. Discover our marble services from production to installation. View our gallery showcasing marble installations and designs."
                />
                <meta
                    name="keywords"
                    content="marble factory, marble production, marble installation, marble services, marble craftsmanship, marble supplier, marble types, natural marble, marble countertops, marble tiles, custom marble design, marble renovation, marble gallery, marble images, marble videos, marble installations, marble designs, marble patterns, marble craftsmanship, custom marble projects, natural marble, marble decor, marble architecture, luxury marble, contemporary marble, classical marble, marble art, marble sculpture, marble flooring, marble countertops, marble slabs, marble kitchen, marble bathroom, marble renovation, marble products, marble slabs, marble tiles, marble countertops, marble flooring, marble sculptures, marble mosaics, marble backsplashes, marble sinks, marble vanity tops, marble fireplaces, marble staircases, custom marble designs, natural marble, luxury marble, exotic marble, marble patterns, marble finishes, marble colors, marble dimensions, marble specifications, marble suppliers, marble exporters, marble distributors, marble showroom, marble catalog"
                />
            </Helmet>


            <div>
                <CommonBanner />
                <div className="Products">
                    {/* <div className="container-fluid">
                        <div className="container">
                            <div className="row parent_class_1">
                                <div className="col-md-3">
                                    <Dropdown  >
                                        <Dropdown.Toggle className="menu_colors"
                                            variant="success" id="dropdown-basic">
                                            Keywords
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu  >
                                            {filteredList.map((item, pos) => (
                                                <Dropdown.Item key={pos} onClick={(event) => {
                                                    console.log(event.target.text);
                                                    setTypeFilter(event.target.text);
                                                }}
                                                    value={item.title} href="">{item.title}</Dropdown.Item>

                                            ))}
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </div>

                                <div className="col-md-3">
                                    <Dropdown  defaultValue="white" >
                                        <Dropdown.Toggle className="menu_colors"
                                            variant="success" id="dropdown-basic" defaultValue="white">
                                            { typeFilter === '' &&
                                            <span>Products</span>
                                            || typeFilter !== '' &&
                                            <span>{typeFilter}</span>
                                            }
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu defaultValue="white" >
                                            {filteredList.map((item, pos) => (
                                                <Dropdown.Item key={pos} onClick={(event) => {
                                                    console.log(event.target.text);
                                                    setTypeFilter(event.target.text);
                                                }}
                                                    value={item.title} href="">{item.title}</Dropdown.Item>

                                            ))}
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </div>

                                <div className="col-md-3">
                                    <Dropdown  >
                                        <Dropdown.Toggle className="menu_colors"
                                            variant="success" id="dropdown-basic">
                                            Types
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu >
                                            {filteredList.map((item, pos) => (
                                                <Dropdown.Item key={pos} onClick={(event) => {
                                                    console.log(event.target.text);
                                                    setProdTypeFilter(event.target.text);
                                                }}
                                                    value={item.productType} href="">{item.productType}</Dropdown.Item>

                                            ))}
                                        </Dropdown.Menu>

                                    </Dropdown>
                                </div>

                                <div className="col-md-3">
                                    <Dropdown className="button_dropdown" >
                                        <Dropdown.Toggle 
                                        // onClick={(event) => {
                                        //     filterByPropertyValue() }}
                                            >
                                            Search Here
                                        </Dropdown.Toggle>

                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="container-fluid mt-5 parent_class_2 ">
                        <div className="container ">
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                                {Object.keys(product).map((item, pos) => {
                                    return (
                                        <div key={pos.toString()}>

                                            <div className="card shadow-sm">
                                                <img src={product[item]?.field_image[0]?.url} alt={`Dar Al Hikmah Marbles Factory - ${product[item]?.field_image[0]?.alt}`} className="img-fluid" />
                                                <div className="card-body">
                                                    <div className="card_block">
                                                        <h5>{product[item]?.title?.[0]?.value}</h5>
                                                        <h5>{product[item]?.field_color?.[0]?.value}</h5>
                                                    </div>
                                                    <p className="card-text">  {renderHTMLEn(product[item]?.body?.[0]?.value)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default Products;
