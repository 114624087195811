import config from "../config";
import axios from "axios";

let HomeApi = config.api.url

export const getMaster = (header) => {
    return(
    axios.get(HomeApi).then(response => {
        header(response.data?.primary_menu?.olivero_main_menu?.data);
    })
        .catch(error => {
            // Handle any errors
            console.error(error);
        })
    )
}