import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";

import '../../src/styles/projects.css'
import Footer from "../components/Footer";
import CommonBanner from "../components/CommonBanner";

import { projectsURL } from "../drupal-global-Api/global";
import { renderHTMLEn } from "../components/renderHTML";

const Projects = () => {
    const [projects, setProjects] = useState([])

    useEffect(() => {
        axios.get(projectsURL).then(response => {
            setProjects(response.data?.content?.olivero_views_block__projects_block_1?.data?.rows);
        })
            .catch(error => {
                console.error(error);
            });

    }, [])

    return (
        <>
            <Helmet>
                <title>Marble Projects - Dar Al Hikmah Marbles Factory</title>
                <meta
                    name="description"
                    content="Explore Dar Al Hikmah's marble projects showcasing our expertise in custom marble designs, installations, renovations, and craftsmanship."
                />
                <meta
                    name="keywords"
                    content="marble projects, marble installations, custom marble designs, marble renovations, marble craftsmanship, marble supplier, marble types, natural marble, luxury marble, contemporary marble, classical marble, marble art, marble sculpture, marble architecture, marble aesthetics, marble flooring, marble countertops, marble slabs, marble kitchen, marble bathroom, marble decor, marble showroom, marble gallery"
                />
            </Helmet>

            <div>
                <CommonBanner />

                <div className="container-fluid" >
                    <div className="container mg-top">
                        <div className="row row-cols-1 row-cols-sm-2">
                            {Object.keys(projects).map((item, pos) => {
                                return (
                                    <div key={pos.toString()} className="">
                                        <div className="card shadow-sm">
                                            <img src={projects[item]?.field_image?.[0]?.url} alt="" className="img-fluid hoverimg" />
                                            <div className="overlay">
                                                <div className="block">
                                                    <h3 className="text">{projects[item]?.title?.[0]?.value}</h3>
                                                    <p className="text_1">{renderHTMLEn(projects[item]?.body[0]?.value)}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <Footer />
            </div >
        </>
    )
}


export default Projects;