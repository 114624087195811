import React, { useEffect, useState } from "react";
import axios from "axios";
import { isMobileOnly } from "react-device-detect";

import '../../src/styles/footer.css';
import { Url } from "../drupal-global-Api/global";
import { Helmet } from "react-helmet";

const Footer = () => {
  const [aboutus, setAboutus] = useState([]);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Url);
        setAboutus(response.data?.content?.footer_top?.aboutusfootermenu);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("about us", aboutus);
  }, [aboutus]);

  let pathArr = [];
  if (typeof window !== 'undefined') {
    pathArr = window.location.pathname.split('/');
  } else {
    pathArr[1] = 'en';
    pathArr[2] = '/';
  }
  const path = pathArr[1] === 'en' ? 'en' : 'ar';

  const englishContent = {
    quickLinks: [
      { label: "Home", url: "/" },
      { label: "Projects", url: `/${path}/projects` },
      { label: "Products", url: `/${path}/products` },
      { label: "Our Services", url: `/${path}/ourservices` },
      { label: "Contact Us", url: `/${path}/contactus` }
    ],
    contactDetails: [
      { label: "Tel: +971 567 867 786", url: "tel:+971567867786" },
      { label: "Email: info@dahmarbles.ae", url: `mailto:info@dahmarbles.ae` },
      { label: "Address: Al Sajaa Industrial - Emirates Industrial City - Sharjah", url: "#" }
    ],
    copyrightText: "© Copyright 2023 Dar Al Hikmah Marbles Factory L.L.C S.P"
  };

  const arabicContent = {
    quickLinks: [
      { label: "الرئيسية", url: `/${path}/` },
      { label: "المشاريع", url: `/${path}/projects` },
      { label: "المنتجات", url: `/${path}/products` },
      { label: "خدماتنا", url: `/${path}/ourservices` },
      { label: "اتصل بنا", url: `/${path}/contactus` }
    ],
    contactDetails: [
      { label: "هاتف: +971 567 867 786", url: "tel:+971567867786" },
      { label: "البريد الإلكتروني: info@dahmarbles.ae", url: `mailto:info@dahmarbles.ae` },
      { label: "العنوان: المدينة الصناعية الصجعة - مدينة الشارقة الصناعية - الشارقة", url: "#" }
    ],
    copyrightText: "© حقوق النشر محفوظة 2023 مصنع دار الحكمة للرخام ش.ذ.م.م"
  };

  const currentContent = path === 'ar' ? arabicContent : englishContent;

  const directionClass = path === 'ar' ? 'rtl' : 'ltr';
  
  return (
    <div className={`container-fluid footer_background ${path === 'ar' ? 'text-right' : ''}`} dir={path === 'ar' ? 'rtl' : 'ltr'}>
       <Helmet>
        <title>{path === 'ar' ? 'مصنع دار الحكمة للرخام ش.ذ.م.م' : 'Dar Al Hikmah Marbles Factory L.L.C S.P'}</title>
        <meta name="description" content={path === 'ar' ? 'مصنع دار الحكمة للرخام ش.ذ.م.م هو مصنع رخام في الشارقة' : 'Dar Al Hikmah Marbles Factory L.L.C S.P is a marble factory in Sharjah'} />
        <meta name="keywords" content={path === 'ar' ? 'مصانع الرخام في الشارقة, رخام, الشارقة' : 'marble factories in Sharjah, marble, Sharjah'} />
      </Helmet>
      <div className="container">
        <footer style={{ direction: path === 'ar' ? 'rtl' : 'ltr' }} dir={path === 'ar' ? 'rtl' : 'ltr'}>
          <div className="row">
            <div className="col-6" style={{ textAlign: path === 'ar' ? 'right' : 'left' }}>
              <h5 style={{ textAlign: path === 'ar' ? 'right' : 'left' }}>{path === 'ar' ? 'روابط سريعة' : 'Quick Links'}</h5>
              <ul className="nav flex-column">
                {currentContent.quickLinks.map((link, index) => (
                  <li key={index} className="nav-item mb-2">
                    <a href={link.url} className="nav-link p-0 text-muted" style={{ textAlign: path === 'ar' ? 'right' : 'left' }} dir={path === 'ar' ? 'rtl' : 'ltr'}>{link.label}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-6" style={{ textAlign: path === 'ar' ? 'right' : 'left' }}>
              <h5 style={{ textAlign: path === 'ar' ? 'right' : 'left' }}>{path === 'ar' ? 'تفاصيل الاتصال' : 'Contact Details'}</h5>
              <ul className="nav flex-column">
                {currentContent.contactDetails.map((detail, index) => (
                  <li key={index} className="nav-item mb-2">
                    {detail.label.startsWith("Tel:") ? (
                      <a href={detail.url} className="nav-link p-0 text-muted" style={{ textAlign: path === 'ar' ? 'left' : 'left' }} dir={path === 'ar' ? 'rtl' : 'ltr'}>{detail.label}</a>
                    ) : (
                      <span className="nav-link p-0 text-muted" style={{ textAlign: path === 'ar' ? 'right' : 'left' }} dir={path === 'ar' ? 'rtl' : 'ltr'}>{detail.label}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </footer>
      </div>
      <div className="d-flex justify-content-between border-top footView">
        <p className="footertext" style={{ textAlign: path === 'ar' ? 'right' : 'left' }} dir={path === 'ar' ? 'rtl' : 'ltr'}>
          <b>{currentContent.copyrightText}</b>
        </p>
        <ul className="list-unstyled d-flex">
          <li className="">
            <a href="#" className="link-dark">
              <svg className="bi" width="24" height="24"><use href="#facebook"></use></svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
