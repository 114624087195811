let globalurl = "https://www.dahmarbles.ae/app/block-layout?path=/";
let pathArr = [];
if (typeof window !== "undefined") {
  pathArr = window.location.pathname.split("/");
} else {
  pathArr[1] = "ar";
}
const path = pathArr[1] === "en" ? "en" : "ar";
let Url = `${globalurl}&_format=json&language=` + path;
// let Url = `${globalurl}&_format=json&language=` + path;
let productsURL = `${globalurl}products&_format=json&language=` + path;
let projectsURL = `${globalurl}projects&_format=json&language=` + path;
let ourservicesURL = `${globalurl}ourservices&_format=json&language=` + path;
let aboutusURL = `${globalurl}aboutus&_format=json&language=` + path;
let conatctUs = `${globalurl}contactus&_format=json&language=` + path;
let ourgallery = `${globalurl}Ourgallery&_format=json&language=` + path;
let test = `${globalurl}test&_format=json&language=` + path;

export {
  Url,
  aboutusURL,
  productsURL,
  projectsURL,
  ourservicesURL,
  ourgallery,
  conatctUs,
  test
};
