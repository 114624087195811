import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import En from '../src/locales/en/product.json'
import Ar from '../src/locales/ar/product.json'


const resources = {
    en: {
      translation:En
    },
    ar: {
      translation:Ar
    }
  };

i18n
.use(initReactI18next)
.init({
    resources,
    lng: "en",
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;