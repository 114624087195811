import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Header from "./components/Header";
import Projects from "./pages/Projects";
import OurServices from "./pages/OurServices";
import ContactUs from "./pages/ContactUs";
import OurGallery from "./pages/OurGallery";

import whatsapp from '../src/assets/whatsapp.png';

export default function App() {

  const languages = [
    {
      code: 'en',
      name: 'EN',
      dir: 'ltr',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
    },
  ];

  let pathArr = [];
  if (typeof window !== 'undefined') {
    pathArr = window.location.pathname.split('/');
  } else {
    pathArr[1] = 'ar';
  }
  const path = pathArr[1] === 'en' ? 'en' : 'ar';

  return (
    <BrowserRouter>
      <Helmet>
        <html lang={path === 'en' ? 'en' : 'ar'} /> {/* Set HTML lang attribute based on language */}
        <title>Your Website Name - {getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>

      <Header />

      {/* WhatsApp Icon with alt text */}
      <div style={{ bottom: 14, right: 16, position: 'fixed', zIndex: 16000160, direction: 'ltr', textAlign: 'right' }} className="d-flex align-items-center flex-row">
        <a href="https://wa.me/971567867786" target="_blank" rel="noopener noreferrer" aria-label="Go to WhatsApp">
          <img src={whatsapp} alt="WhatsApp Icon" className="img-fluid" style={{ width: '60px' }} />
        </a>
      </div>

      <main>
        <Routes>
          {/* Home Page */}
          <Route path={`/`} element={<Home />} />
          <Route path={`${path}/`} element={<Home />} />
          <Route path={`${path}/projects`} element={<Projects />} />
          <Route path={`${path}/products`} element={<Products />} />
          <Route path={`${path}/ourservices`} element={<OurServices />} />
          <Route path={`${path}/contactus`} element={<ContactUs />} />
          <Route path={`${path}/Ourgallery`} element={<OurGallery />} />

          {/* <Route path={`${path}/aboutus`} element={<AboutUs />} /> */}
        </Routes>
      </main>
    </BrowserRouter>
  );

  function getPageTitle() {
    switch (pathArr[1]) {
      case 'en':
        return 'Marble Products - Enhance Your Home with Luxury Marble';
      case 'ar':
        return 'منتجات الرخام - قم بتحسين منزلك بالرخام الفاخر';
      default:
        return 'Marble Products - Enhance Your Home with Luxury Marble';
    }
  }

  function getPageDescription() {
    switch (pathArr[1]) {
      case 'en':
        return 'Explore our range of marble products including tiles, countertops, and sculptures. Transform your space with luxurious marble from our expert craftsmen.';
      case 'ar':
        return 'استكشف مجموعتنا من منتجات الرخام بما في ذلك البلاط والأسطح والنحت. قم بتحويل مساحتك باستخدام الرخام الفاخر من حرفيينا المتخصصين.';
      default:
        return 'Explore our range of marble products including tiles, countertops, and sculptures. Transform your space with luxurious marble from our expert craftsmen.';
    }
  }
}