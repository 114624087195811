import React from "react";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import "../../src/styles/homeBanner.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isMobileOnly, isTablet } from "react-device-detect";
import Homeban from "../locales/homeBanner.json"; // Assuming this is correctly structured with Arabic and English content

const Slick = () => {
    // Determine language path ('en' or 'ar'), defaulting to 'en'
    const path = typeof window !== 'undefined' && window.location.pathname.split('/')[1] === 'ar' ? 'ar' : 'en';

    const settingsOne = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 100,
        autoplay: true,
        initialSlide: 0,
        autoplaySpeed: 3000, // Change autoplay speed to 3000 milliseconds (3 seconds)
        arrows: true,
    };

    return (
        <div className="slickSlides" style={{ overflow: 'hidden' }}>
            <Slider {...settingsOne}>
                {Homeban.map((item, pos) => (
                    <div key={pos} className="slick-inner-wr" style={{ position: 'relative' }}>
                        <div className="image" style={{
                            backgroundImage: `url(${isMobileOnly ? item.BannerImgMobile : item.BannerImg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            width: isMobileOnly ? 'auto' : isTablet ? '100%' : '100vw', // Adjust width for mobile and tablet views
                            height: isMobileOnly ? '50vh' : isTablet ? '60vh' : '100vh', // Adjust height for mobile and tablet views
                            position: 'relative', // Ensure the image container is relative for absolute positioning
                        }}>
                            {/* Render a fallback image in case background image fails to load */}
                            <img src={isMobileOnly ? item.BannerImgMobile : item.BannerImg} alt={`Banner ${pos + 1} - ${item.title[path]}`} style={{ display: 'none' }} />

                            <div className="text-overlay" style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                textAlign: isMobileOnly ? 'left' : 'center', // Align text to left on mobile devices
                                backgroundColor: 'rgba(0,0,0,0.5)', // Add a semi-transparent black background
                                padding: '20px', // Increase padding to make the background visible
                                borderRadius: '10px', // Add a rounded corner to the background
                                color: '#fff', // Keep the text color white
                                textShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                                width: isMobileOnly ? '80%' : isTablet ? '70%' : '60%', // Increase width of text area
                                maxWidth: isMobileOnly ? '400px' : 'auto' // Set a maximum width for the text area
                            }}>
                                <h2 className="title" style={{
                                    fontSize: isMobileOnly ? '18px' : isTablet ? '22px' : '36px', // Adjust font size for mobile and tablet views
                                    fontWeight: 'bold',
                                    marginBottom: '10px',
                                }}>{item.title[path]}</h2>
                                <p className="desc" style={{
                                    fontSize: isMobileOnly ? '12px' : isTablet ? '14px' : '18px', // Adjust font size for mobile and tablet views
                                    lineHeight: '1.5', // Increase line height for better readability
                                }}>{item.desc[path]}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
            {/* Meta tags for SEO */}
            <Helmet>
                <title>{path === 'ar' ? 'السلايدر الرخامي - أفضل الرخام لمنزلك' : 'DAH Marble Sliders - Best Marbles for Your Home'}</title>
                <meta name="description" content={path === 'ar' ? 'استكشف مجموعتنا المتنوعة من الرخام الفاخر لديكور منزلك. اكتشف مجموعة متنوعة من الألوان والأنسجة لتناسب كل أسلوب.' : 'Explore our selection of premium marbles for your home decor. Discover a variety of colors and textures to suit every style.'} />
                <meta name="keywords" content={path === 'ar' ? 'الرخام، ألواح الرخام، بلاط الرخام، ديكور المنزل، تصميم الداخلي، حجر طبيعي، أسطح الرخام' : 'marbles, marble slabs, marble tiles, home decor, interior design, natural stone, marble countertops'} />
                <meta property="og:title" content={path === 'ar' ? 'السلايدر الرخامي - أفضل الرخام لمنزلك' : 'Marble Sliders - Best Marbles for Your Home'} />
                <meta property="og:description" content={path === 'ar' ? 'استكشف مجموعتنا المتنوعة من الرخام الفاخر لديكور منزلك. اكتشف مجموعة متنوعة من الألوان والأنسجة لتناسب كل أسلوب.' : 'Explore our selection of premium marbles for your home decor. Discover a variety of colors and textures to suit every style.'} />
            </Helmet>
        </div>
    );
}


 export default Slick;
