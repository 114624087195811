import { useState, useEffect } from 'react';
import axios from "axios";
import { Helmet } from 'react-helmet';
import { ourgallery } from "../drupal-global-Api/global";
import CommonBanner from "../components/CommonBanner";
import '../styles/gallery.css'
import { ImageGroup, Image } from 'react-fullscreen-image'
import ReactPlayer from 'react-player'
import Footer from '../components/Footer';

function Ourgallery() {
    const [gallery, setGallery] = useState([])
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [showModal, setShowModal] = useState(true);

    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    useEffect(() => {
        axios.get(ourgallery).then(response => {
            setGallery(response.data?.content?.olivero_views_block__gallery_block_1?.data?.rows);
            console.log(gallery)
        })
            .catch(error => {
                // Handle any errors
                console.error(error);
            })
        // setMenu(Url)

    }, [])

    useEffect(() => {
        const imageUrls = [];
        const videoUrls = []
        for (const item of gallery) {
            if (item["field_gallery_selection"]?.[0]?.value === "images") {
                imageUrls.push({
                    src: item["field_image"][0]["url"],
                    alt: item["field_image"][0]["alt"], // Assuming your API provides alt text for images
                });
            }
            else if (item["field_gallery_selection"]?.[0]?.value == "videos") {
                videoUrls.push(item["field_video"][0]["uri"]);
            }
        }
        setImages(imageUrls)
        setVideos(videoUrls)
    }, [gallery]);
    return (
        <>
            <Helmet>
                <title>Our Gallery - Dar Al Hikmah Marbles Factory</title>
                <meta
                    name="description"
                    content="Explore Dar Al Hikmah's gallery of images and videos showcasing our marble works and installations."
                />
                <meta
                    name="keywords"
                    content="marble gallery, marble images, marble videos, marble installations, marble designs, marble patterns, marble craftsmanship, custom marble projects, natural marble, marble decor, marble architecture, luxury marble, contemporary marble, classical marble, marble art, marble sculpture, marble flooring, marble countertops, marble slabs, marble kitchen, marble bathroom, marble renovation"
                />
            </Helmet>

            <div>
                <CommonBanner />
            </div>
            <div className="container-fluid">
                <div className='container'>
                    <div className='row blockImages'>
                        <div className='col-sm-6 borderbottom' onClick={handleModalShow}>
                            <h5 className="common_Text">Images</h5>
                        </div>
                        <div className='col border-start' onClick={handleModalClose}>
                            <h5 className="common_Text">Videos</h5>
                        </div>
                    </div>
                    {showModal &&
                        <ImageGroup>
                            <ul className="images">
                                {images.map((item, i) => {

                                    return (
                                        <li key={i}>

                                            <Image
                                                src={item.src}
                                                alt={item.alt}
                                                style={{
                                                    position: 'absolute',
                                                    top: 15,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    height: '90%',
                                                    width: ' 90%',
                                                    objectFit: 'cover',

                                                }}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        </ImageGroup>
                    }
                    {
                        !showModal &&
                        <div className='row'>
                            {videos.map((videoUrl, index) => (
                                <div key={index} className='col-md-6 videoView' >
                                    <ReactPlayer url={videoUrl} className='mobileview' />
                                </div>
                            ))}
                        </div>
                    }
                </div>

            </div>

            <Footer />

        </>
    );
}





export default Ourgallery