import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


const LangLink = React.forwardRef((props,ref) => {

    const [ path, setPath ] = useState(props.to);
    const { children } = props;


    useEffect(() => {
        const to = props.to.replace('/app/', '');
        const p = '/'+ to;
        setPath(p);
    }, [ path]);
    
    return (
        <Link className='link-LangLink' {...props} ref={ref} to={path}>{children}</Link>
    );
})

export default LangLink;