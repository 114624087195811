const config = {
    api: {
        url: 'https://www.dahmarbles.ae/app/en/projects',
        domain: 'http://localhost:8000'  
      },
      site: { 
        url: 'https:///www.dahmarbles.ae/',
        careersUrl: 'http://careers.bidaya.com.sa/'
      }
  };
  export default config;