import React, { useEffect } from "react";
import axios from "axios";

import "../styles/home.css";

import Footer from "../components/Footer";
import { renderHTMLEn } from "../components/renderHTML";

import { Url } from "../drupal-global-Api/global";
import Slick from "../components/Slick";

import { Helmet } from "react-helmet";


const Home = () => {

  const [product, setProduct] = React.useState([]);
  const [project, setProject] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [test, setTest] = React.useState([]);
  // const [pageLink, setPageLink] = React.useState([])


  useEffect(() => {
    axios.get(Url).then(response => {
      setProject(response.data?.content?.olivero_homeblock?.data);
      setProduct(response.data?.content?.olivero_ourproducts?.data);
      setServices(response.data?.content?.olivero_ourservices?.data);

    })
      .catch(error => {
        // Handle any errors
        console.error(error);
      })
    axios.get(test).then(response => {
      setTest(response.data?.content);
      console.log("test Url", test)

    })
      .catch(error => {
        // Handle any errors
        console.error(error);
      })
    // setMenu(Url)
  }, [])


  let pathArr = [];
  if (typeof window !== 'undefined') {
    pathArr = window.location.pathname.split('/');
  } else {
    pathArr[1] = 'en';
    pathArr[2] = 'ar';
  }

  const path = pathArr[1] === 'en' ? 'en' : 'ar';
  console.log("path", path)
  return (
    <>
      <Helmet>
        <title>Dar Al Hikmah Marbles Factory - Marble Products, Services, Gallery</title>
        <meta
          name="description"
          content="Explore Dar Al Hikmah Marbles Factory. We offer a wide range of marble products including slabs, tiles, countertops, and custom designs. Discover our marble services from production to installation. View our marble gallery showcasing installations, designs, patterns, craftsmanship, and aesthetic appeal."
        />
        <meta
          name="keywords"
          content="marble factory, marble production, marble installation, marble services, marble craftsmanship, marble supplier, marble types, natural marble, marble countertops, marble tiles, custom marble design, marble renovation, marble gallery, marble images, marble videos, marble installations, marble designs, marble patterns, marble craftsmanship, custom marble projects, natural marble, luxury marble, contemporary marble, classical marble, marble art, marble sculpture, marble architecture, marble renovation, marble aesthetics, marble flooring, marble countertops, marble slabs, marble kitchen, marble bathroom, marble decor, marble showroom"
        />
      </Helmet>

      <Slick />

      <div className="container-fluid">
        <div className="container">
          <div className="row AlignItemCenter">
            <div className="col-md-7">
              <h1> <a className="h1A" href={`/${path}/products`} >{product?.info?.[0]?.value}</a></h1>
              <p>{renderHTMLEn(product?.body?.[0]?.value)}</p>
            </div>
            <div className="col-md-5 Homepage_Ani">
              <img src={product?.field_image?.[0]?.url} alt={`Dar Al Hikmah Marbles Factory - ${product?.field_image?.[0]?.url}`} className="img-fluid mx-auto" />
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid CommonTop3">
        <div className="container">
          <div className="row AlignItemCenter">
            <div className="col-md-5 Homepage_Ani_1">
              <img src={services?.field_image?.[0]?.url} alt={`Dar Al Hikmah Marbles Factory - ${services?.field_image?.[0]?.url}`} className="img-fluid mx-auto" />
            </div>
            <div className="col-md-7">
              <h1><a className="h1A" href={`/${path}/ourservices`}>{services?.info?.[0]?.value}</a></h1>
              <h5 className="CommonFont Block2paragraph">{services?.body?.[0]?.summary}</h5>
              <p>{renderHTMLEn(services?.body?.[0]?.value)}</p>

            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row AlignItemCenter">
          <div className="col-md-7">
            <h1><a className="h1A" href={`/${path}/projects`}>{project?.info?.[0]?.value}</a></h1>
            <p>{renderHTMLEn(project?.body?.[0]?.value)}</p>
          </div>
          <div className="col-md-5 Homepage_Ani">
            <img src={project?.field_image?.[0]?.url} alt={`Dar Al Hikmah Marbles Factory - ${project?.field_image?.[0]?.url}`} className="img-fluid mx-auto" />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Home;