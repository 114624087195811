import React, { useState, useEffect } from "react";
import CommonBanner from "../components/CommonBanner";
import axios from "axios";
import { conatctUs } from "../drupal-global-Api/global";

import "../../src/styles/ContactUs.css";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const ConatctUs = () => {

  const [name, setName] = useState(localStorage.getItem('name') || '');
    const [tel, setTel] = useState(localStorage.getItem('tel') || '');
    const [email, setEmail] = useState(localStorage.getItem('email') || '');
    const [address, setAddress] = useState(localStorage.getItem('address') || '');
    const [telError, setTelError] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
};

const handleTelChange = (e) => {
    setTel(e.target.value);
};

const handleEmailChange = (e) => {
    setEmail(e.target.value);
};

const handleAddressChange = (e) => {
    setAddress(e.target.value);
};

const handleSubmit = (e) => {
    e.preventDefault();
    if (!tel) {
      setTelError('Please enter your number.');
      return;
  } else {
      setTelError('');
  }
    localStorage.setItem('name', name);
    localStorage.setItem('tel', tel);
    localStorage.setItem('email', email);
    localStorage.setItem('address', address);
    alert('Contact information saved!');
};

  return (
    <>
      <Helmet>
        <title>Contact Dar Al Hikmah Marbles Factory</title>
        <meta
          name="description"
          content="Contact Dar Al Hikmah Marbles Factory for inquiries about our marble products, services, projects, and collaborations."
        />
        <meta
          name="keywords"
          content="contact Dar Al Hikmah, marble factory contact, marble products inquiry, marble services, marble projects, marble collaborations, marble supplier contact, marble showroom, marble distributor, marble installation service, marble renovation service, custom marble design, natural marble, luxury marble, contemporary marble, classical marble, marble art, marble sculpture, marble architecture"
        />
      </Helmet>

      <div>
        <CommonBanner />
      </div>
      <div className="container">
        <div className="container-fluid">
          <div className="row contactus-main">
            <div className="col-12">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.670358013411!2d55.6269316!3d25.3488403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef5f30739d5895b%3A0xbc0c7af4e61ef1e9!2sDAR%20Al%20HIKMAH%20marble%20factory%20LLC%20sp!5e0!3m2!1sen!2sae!4v1720186023643!5m2!1sen!2sae"
                width="100%"
                height="100%"
                loading="fast">
              </iframe>

            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 text-center">
                <h3>Sharjah</h3>
                <form onSubmit={handleSubmit}>
                    <div className="contact-info">
                        <div className="input-group mb-3">
                            <span className="input-group-text tel-font">Name</span>
                            <input type="text" className="form-control" placeholder="Enter your name" value={name} onChange={handleNameChange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text tel-font">Tel</span>
                            <input type="text" className="form-control" placeholder="Enter your number" value={tel} onChange={handleTelChange} />
                        </div>
                        {telError && <div className="text-danger mb-3">{telError}</div>}
                       
                        <div className="input-group mb-3">
                            <span className="input-group-text tel-font">Email</span>
                            <input type="email" className="form-control" placeholder="Enter your email address" value={email} onChange={handleEmailChange} />
                        </div>
                        <div className="input-group mb-3">
                            <span className="input-group-text tel-font">Address</span>
                            <input type="text" className="form-control" placeholder="Enter your address" value={address} onChange={handleAddressChange} />
                        </div>
                        <button type="submit" className="btn btn-primary">Save Contact Information</button>
                    </div>
                </form>
            </div>
        </div>

        </div>
      </div>
      <Footer />
    </>
  );
};
export default ConatctUs;
